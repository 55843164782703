.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: min(600px, 90vh);
}

.fstCol {
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  height: 400px;
  justify-content: space-between;
  align-items: space-between;
}

.sndCol {
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  /* background-color: red; */
  align-items: center;
}
.img-notify {
  height: 60%;
  width: 60%;
  /* background-color: blue; */
}

.btnDefault {
  background-color: #638a2f;
  width: 200px;
  height: 50px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
  margin-right: 20px;
}

.btnRow {
  display: flex;
  flex-direction: row;
}

.wrapRow {
  margin-bottom: 30px;
}

.bottomRow {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.longInputBox {
  height: 40px;
  width: 500px;
  border: 2px solid #2e64ae;
  border-radius: 4px;
  font-size: 15px;
  padding-top: 10px;
  padding-left: 10px;
}
