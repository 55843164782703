.completionContainer {
  display: flex;
  flex-direction: column;
  justify-self: space-between;
  align-items: center;
  /* background-color: darkolivegreen; */
  height: 420px;
}
.completionHeading {
  color: #2e64ae;
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 30px;
}

.completionDescription {
  color: #908f8f;
  font-weight: bold;
  max-width: 900px;
  text-align: center;
}

.bubble {
  height: 200px;
  margin-bottom: 20px;
}
