.masterContainer {
  padding-right: min(50px, 5%);
  padding-left: min(50px, 5%);
  padding-top: min(10px, 5%);
  /* padding-bottom: min(10px, 5%); */
  /* background-color: aqua; */
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  /* background-color: cadetblue; */
}
.clientLogo {
  height: 130px;
}
.afyaLogo {
  height: 40px;
}

.footer {
  color: #2e64ae;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  /* background-color: brown; */
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*FORM*/

.label {
  color: #2e64ae;
  font-size: 15px;
  font-weight: bold;
}

.inputBox {
  height: 40px;
  width: 250px;
  border: 2px solid #2e64ae;
  border-radius: 4px;
  font-size: 15px;
  padding-top: 10px;
  padding-left: 10px;
}

.bigInput {
  height: 130px;
  width: 500px;
  border: 2px solid #2e64ae;
  border-radius: 4px;
  font-size: 15px;
  padding-top: 10px;
  padding-left: 10px;
}
.field {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  min-width: 1000px;
  max-width: 1000px;
  justify-content: space-between;
}

.formContainer {
  display: flex;
  flex-direction: column;
  min-height: 420px;
  max-height: 900px;
  justify-content: space-between;
  align-items: center;
  /* background-color: burlywood; */
  /* margin-bottom: 50px; */
}

.submitBtn {
  background-color: #638a2f;
  border: 0px solid #638a2f;
  width: 100px;
  height: 40px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
  /* margin-top: 40px; */
  margin-right: 90px;
}

.sendBtn {
  background-color: #638a2f;
  border: 0px solid #638a2f;
  width: 200px;
  height: 40px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
}

button:hover {
  background-color: #7da14c;
}

.msgRow {
  display: flex;
  flex-direction: row;
  width: 1200px;
  align-items: space-between;
  justify-content: space-between;
  /* background-color: cadetblue; */
  margin-bottom: 30px;
}

.sendBtnRow {
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* background-color: darkgoldenrod; */
}

.msgContainer {
  min-height: 300px;
  width: 100%;
  padding-top: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: cornflowerblue; */
}

.infoRow {
  display: flex;
  flex-direction: row;
  width: 1200px;
  justify-content: flex-start;
  align-items: center;
  /* background-color: darkred; */
  margin-bottom: 30px;
}

.infoSeg {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-right: 50px;
  font-size: 20px;
  /* font-weight: bold; */
}

.patientName {
  font-size: 20px;
  font-weight: bold;
  color: #638a2f;
}

.infoLabel {
  color: #c5c5c5;
  font-size: 15px;
  text-transform: capitalize;
  margin-bottom: 0px;
  font-weight: normal;
  /* background-color: darkviolet; */
}

.appBtnRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
