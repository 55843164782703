.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  /* background-color: cornflowerblue; */
  min-height: 400px;
  max-height: min(600px, 40%);
}

.headline {
  color: #2e64ae;
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 40px;
  max-width: 80vw;
}

.description {
  font-size: 25px;
  margin-bottom: 30px;
  max-width: 80vw;
}

.fstRow {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  height: 70%;
  /* background-color: darkolivegreen; */
}

.sndRow {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  /* background-color: red; */
  align-items: center;
}
.img {
  height: 70%;
  width: 70%;
  /* background-color: blue; */
}

.btnDefault {
  /* background-color: #638a2f; */
  width: 200px;
  height: 50px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
  margin-right: 20px;
  border: none;
}

.btnOther {
  background-color: white;
  width: 200px;
  height: 50px;
  border: 3px solid #638a2f;
  border-radius: 10px;
  color: #638a2f;
  font-weight: bold;
  font-size: 18px;
}

.btnOther:hover {
  background-color: #f1f1f1;
}

.btnRow {
  display: flex;
  flex-direction: row;
}
