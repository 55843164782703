* {
  margin: 0px;
  padding: 0px;
}
.room {
  width: calc(100vw);
  height: calc(100vh);

  margin: 0px;
  padding: 0px;
  border: none;
}
